:root {
  --color: #3F4195;
}

@media (min-width: 1400px) {
  .container-fluid {
    max-width: 1400px;
  }
  .breadcrumb-section {
    max-width: 1400px;
    margin: 0 auto;
  }
  .search-section {
    max-width: 1400px;
    margin: 0 auto;
  }
  .feature-section {
    overflow: hidden;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: -80px;
  }

  .main-navbar {
    max-width: 1400px;
    margin: 0 auto;
  }

  .main-navbar.stick-nav {
    display: block;
    margin: 0 auto;
  }
  .sticky-social {
    left: unset;
    margin-left: -24px;

  }
}

@media (max-width: 1326px) {
  .feature-section .feature-box {
    margin: 0;
  }
}

@media (max-width: 1200px) {

  .main-navbar nav {
    padding-right: 50px;
  }

  .main-navbar nav ul li a {
    padding: 10px 16px;
  }

.feature-section .feature-box {
    padding: 30px 20px;
}

  .counselling-section p {
    padding: 0 80px;
}

  .whyus-section .whyus-block{
  margin: 0 4px;
  }

  
.college-carousel {
  margin: 0 !important;
}
  
  .testimonial-block {
    padding: 30px;
    margin: 80px 60px 0 60px;
}

.testimonial-block p {
  margin: 40px 0;
  margin: 0 20px;
  margin-top: 30px;
}
    

.achievement {
  margin: 0 20px;
}

.left-college-detail, .left-news-detail {
  padding: 0;
}
.profile-left-section .profile-details{
  padding: 0 4px;
padding-bottom: 18px;
}

.blog-box {
  margin: 0;
}
}

@media (max-width: 1200px) and (min-width: 991px) {
  .courseList-box img {
    max-width: 68px;
    height: auto;
}
}

@media (max-width: 1024px) {

  .counselling-section p {
    padding: 0 40px;
}

  .main-navbar nav ul li a {
    font-size: 15px;
  }

  .main-navbar nav ul li.dropdown a::after {
    right: 3px;
  }

  .goals-section .goalBox {
    grid-template-columns: 1fr 1fr;
  }

    .whyus-section .whyus-block{
      margin: 0 4px;
      /* margin-bottom: 82px; */
      min-height: 136px;
    }

    .nav-tabs .nav-link{
      margin: 0;
    }
}

@media (max-width: 991px) {

#loginModal .form-content {
  padding: 40px 28px;
}

.main-navbar.stick-nav {
  position: fixed;
}

.feature-section::before, .feature-section::after {
  top: -228px;
}

  .search-section {
    height: 450px;
  }

  .search-section h2 {
    font-size: 30px;
}

#counselling-modal.modal-dialog {
  max-width: 90%;
  margin: 0 auto;
}

.close-btn {
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
}

.modal iframe {
  min-width: 100%;
  max-width: 100% !important;
  height: 90vh !important;
}

  .goals-section .goalBox {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 14px;
  }

  .filter-buttons li a {
    margin: 12px;
    padding: 6px 20px;
    font-size: 15px;
  }

  
  .slick-slider {
    margin: 0;
  }

  .slick-arrow {
    height: 34px;
    width: 34px;
  }

  .slick-next::before, .slick-prev::before {
    font-size: 15px;
  }

  .slick-next::after, .slick-prev::after {
    height: 17px;
  }

  .testimonial-block {
    padding: 30px;
    margin: 60px 20px 0 20px;
}

.testimonial-block .img-sec {
  height: 120px;
  width: 120px;
  margin-top: -90px;
}

.testimonial-block::before, .testimonial-block::after {
  height: 40px;
    width: 40px;
}

.testimonial-slider .slick-prev {
  left: -40px;
}

.testimonial-slider .slick-next {
  right: -40px;
}

.contact-left {
  padding: 0 40px;
}

.contact-block span {
  font-size: 15px;
}

.achievement {
  padding: 15px;
  margin: 0;
}

.carrer_form .career_form_content {
  max-width: 576px;
padding: 40px;
padding-bottom: 20px;
}

.nav-tabs {
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
}

.nav-tabs .nav-item {
  display: inline;
}

.nav-tabs .nav-link {
  display: inline;
}

.nav-tabs .nav-link:hover {
  background: transparent;
  color: black !important;
}

.nav-tabs .nav-link.active {
  background: var(--color) !important;
  color: #ffffff !important;
}


.main-navbar .logo img {
  height: 44px;
width: auto;
}

.detail-block .single-detail {
  max-width: 100%;
}

.top-apply-section .clg-logoo {
  margin-right: 30px;
}

.top-apply-section .secondary-btn {
  margin-right: 8px;
}
.college-details-section .table-responsive,
.college-details-section table,
.college-details-section .table {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}
.goals-section .goalsList {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}
}

@media (max-width: 838px) {
  /* .top-apply-section .clg-logoo {
    height: 140px;
    width: 140px;
  } */
}

@media (max-width: 768px) {

  .common-heading h2 {
    font-size: 22px;
  }

  .drop-search.show {
    padding: 20px 26px 24px 26px;
  }

  .drop-search h6{
    font-size: 14px;
  }

  .search-section {
    height: 90vh;
    background-position: center;
  }

  .search-section h2 {
    font-size: 24px;
}

.search-section .search-block {
  width: 92%;
  margin-top: 30px;
}

#counselling-modal.modal-dialog {
  max-width: 100%;
  margin: 0 auto;
}

#counselling-modal iframe {
  min-width: 100%;
  width: 100%;
}

  .counselling-section .slick-slider {
    margin-bottom: 60px;
  }

  .counselling-section p {
    padding: 0 20px;
}
  
  .whyus-section .whyus-block {
    min-height: 120px;
  }

  .college_block .img-box {
    margin: 0 10px;
}

.college-desc img {
  margin-right: 0;
}

/* .college-desc .college-logo img {
  width: 80%;
  max-height: 72px;
} */

.college-desc {
  display: block;
  text-align: center;
  margin: 0 20px;
  margin-top: -20px;
  min-height: 120px;
  max-height: 120px;
}
.college-desc .college-logo img {
  margin-right: 0;
}

.college-desc h5 {
  font-size: 14px;
}

.college-desc h6 {
  font-size: 12px;
}

.college-desc .college-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

  .filter-buttons {
    overflow-x: scroll;
  }

.filter-buttons::-webkit-scrollbar {
  height: 3px;
}

.filter-buttons::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); 
  border-radius: 10px;
}
 
.filter-buttons::-webkit-scrollbar-thumb {
  background: var(--color); 
  border-radius: 10px;
}

.filter-buttons::-webkit-scrollbar-thumb:hover {
  background: var(--color); 
}

  .slick-arrow {
    height: 30px;
    width: 30px;
  }

  .slick-next::before, .slick-prev::before {
    font-size: 14px;
  }

  .slick-next::after, .slick-prev::after {
    height: 15px;
  }

  footer .contact {
    margin-top: 30px;
  }

  footer .footer-list h5{
    margin-bottom: 13px;
  }

  .footer-bottom > div,
  .footer-bottom  a{
    font-size: 12px;
  }

  .social-links {
    margin: 25px 0;
}

footer hr {
  margin: 0 0 25px 0;
}

.contact-section {
  padding-top: 40px;
}

.breadcrumb-section {
  margin-bottom: 80px;
}

.top-apply-section {
  margin-top: -117px;
}

.top-apply-section .right-desc {
  padding-top: 58px;
}

.top-apply-section .clg-logoo {
    height: 148px;
    width: 148px;
}

.top-apply-section img {
  height: 92px;
  width: 140px;
}

.top-apply-section .college-title {
  font-size: 16px;
}

.top-apply-section .location-details span {
  font-size: 13px !important;
}

.star-container svg {
  height: 16px !important;
  width: 16px !important;
}

.nav-tabs .nav-link {
  padding: 8px 18px;
  font-size: 14px;
}

.filter-downdown {
  padding: 10px 4px 20px 0;
}

.filter-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  transition: .5s ease;
}

.filter-area.show__filters {
  z-index: 1000;
  opacity: 1;
}

.filter-dropdown-content ul {
  max-height: 90vh;
  overflow-y: auto;
}

.form_check span {
  font-size: 13px;
  font-weight: 500;
}

.form_check span:not(.checkmark) {
  padding-left: 4px;
}

.checkmark {
  top: 6px;
  height: 11px;
  width: 11px;
  border-radius: 2px;
}

.checkmark::before {
  font-size: 8px;
}

.blog-section {
  padding: 0;
}

.about-course {
  padding: 12px;
}

.slick-prev,
.testimonial-slider .slick-prev {
 left: -24px;
}

.slick-next ,
.testimonial-slider .slick-next {
 right: -24px;
}

.notFound-section h2 {
  font-size: 26px;
}

.notFound-section p {
  margin: 18px 0 18px 0;
  font-size: 15px;
}
.placementRow {
  grid-template-columns: 1fr 1fr 1fr;
}
.goals-section .goalsList {
  grid-template-columns: 1fr 1fr;
}
.feature-section {
  margin-bottom: 0 !important;
}
}

@media (max-width: 600px) {

  .slick-arrow {
    height: 28px;
    width: 28px;
  }

  .slick-next::after, .slick-prev::after {
    height: 14px;
  }

  .slick-dots li {
    margin: 0 4px;
  }

  .slick-dots li button {
    height: 8px;
    width: 8px;
  }

  
.slick-dots li.slick-active button {
  height: 14px;
  width: 14px;
}

.slick-dots li button::before {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
}

.counselling-section p {
  padding: 0;
}

.studyAbroad_block {
  padding: 15px;
}

  .testimonial-slider {
    margin: 0;
}

  .testimonial-block {
    padding: 20px;
    margin: 50px 0 0 0;
}

.testimonial-block p {
  margin: 20px 0 0 0;
  line-height: 22px;
}

.testimonial-block h5 {
  font-size: 15px;
  font-weight: 700;
  margin-top: 12px;
  margin-bottom: 24px;
}

.testimonial-block .img-sec {
  height: 94px;
  width: 94px;
  margin-top: -70px;
}

.testimonial-block::before, .testimonial-block::after {
  height: 28px;
    width: 28px;
}
}

@media (max-width: 576px) {

  .container-fluid {
    padding: 0 16px;
  }

  .common-heading h2 {
    font-size: 20px;
    font-weight: 700;
  }

  #loginModal .form-content {
    padding: 30px 20px;
  }

  .drop-search.show {
    padding: 16px 20px 24px 16px;
  }

  .drop-search h6{
    font-size: 13px;
    color: #7c7c7c;
  }

  .search-box {
    right: 16px;
  }

  .search-section {
    height: 400px;
  }

  .feature-section::before, .feature-section::after {
    top: -296px;
}

.feature-section .feature-box {
  padding: 20px 6px;
}

.search-section h2 {
  font-size: 20px;
}

.search-section .search-block {
width: 98%;
}

.search-section .search-block .input-group {
  height: 40px;
  border-radius: 5px;
  overflow: visible;
}

.search-section .search-block .input-group input {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.search-section .search-block .input-group .input-group-text svg {
  height: 15px !important;
  width: auto;
}

.search-section .search-block .input-group button.primary-btn,
.search-section .search-block .input-group a {
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
  border-radius: 5px !important;
}

.search-section .search-block .input-group a {
  padding-top: 9px;
}

.goal-block h5 {
  font-size: 14px;
}

.goal-block .img-box {
  width: 36px;
}
.goal-block img {
  height: 26px;
}
.goals-section .goalBox {
  grid-template-columns: 1fr 1fr;
}
  
  .modal iframe {
    min-width:100%;
    max-width: 100% !important;
    height: 90vh !important;
  }

  .whyus-section .whyus-block img {
    height: 44px;
}

  .whyus-section .whyus-block {
    padding: 16px 5px;
    margin: 0 0px;
    margin-bottom: 20px;
  }

  .whyus-section .whyus-block h6 {
    font-size: 14px;
}

  .whyus-section .whyus-block {
    min-height: auto;
  }

  .news-block .news-content {
    padding: 14px 10px;
}

.college_block .img-box {
  margin: 0;
}

.contact-left {
  padding: 0 20px;
}

.contact-block span {
  font-size: 14px;
}

.contact-form {
  padding: 20px;
}

.achievement {
  padding: 10px;
  margin: 0;
  min-height: 184px;
}

.achievement h3 {
  padding: 20px 0 8px 0;
  font-size: 18px;
  font-weight: 700;
}

.achievement h6 {
  font-size: 14px;
}

.accordion-item {
  padding: 0;
}

.accordion-header button  {
  padding: 14px;
}

.accordion-header button span {
  font-size: 13px !important;
  font-weight: 600;
}

.jobTitle {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 6px;
}

.carrer_form .career_form_content {
max-width: 90%;
padding: 30px 14px;
padding-bottom: 10px;
}

.college-details-section table td,
.college-details-section table td a {
  font-size: 13px;
  padding: 10px;
  vertical-align: middle !important;
  min-width: unset !important;
  max-width: unset !important;
  width: unset !important;
}

.profile-right-section {
  padding: 20px 12px;
  border-radius: 10px;
}

.slick-prev,
.testimonial-slider .slick-prev {
 left: -16px;
}

.slick-next ,
.testimonial-slider .slick-next {
 right: -16px;
}

#applyNow-modal .college-heading-block img {
  max-height: 48px;
}

#applyNow-modal .college-heading-block h4 {
  font-size: 16px;
}

#applyNow-modal .college-heading-block h6 {
  font-size: 14px !important;
}

.admission_form {
  margin: 0;
  padding: 18px;
}

.courseList-box .location-details span {
  font-size: 12px;
}

.top-apply-section {
  margin-top: -104px;
}

.top-apply-section img {
  height: 80px;
  width: 120px;
}

.top-apply-section .clg-logoo {
  height: 130px;
  width: 130px;
}

.top-apply-section .right-desc {
  padding-top: 40px;
}

.breadcrumb-content {
  padding-left: 4px;
}
.breadcrumb li {
    position: relative;
    padding: 2px 12px 2px 6px;
}

.breadcrumb li,
.breadcrumb li a {
  font-size: 12px;
}

.breadcrumb li svg {
  font-size: 14px;
  margin-top: -2px;
}
.breadcrumb li:not(:last-child):before {
  font-size: 8px;
  top: 2px;
}

.breadcrumb-content h3 {
  font-size: 17px;
}

.top-apply-btn span {
  padding: 8px 30px;
}
.top-apply-btn .apply-imgg {
  padding: 8px 12px;
}
.top-apply-btn .apply-imgg svg {
  max-height: 18px;
}
.placementRow { 
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
}
.placement_box h4 {
  font-size: 14px;
}
.goals-section .goalsList {
  grid-template-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}
.feature-section .feature-box img {
  max-height: 50px !important;
}
.feature-section .feature-box h5 {
  margin-top: 12px;
}
.college-desc {
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px 4px;
}
.sticky-social {
  bottom: 10px;
}
.sticky-social a i {
  height: 32px;
  width: 32px;
  margin-bottom: 5px;
}
}

@media (max-width: 476px) {
  .top-apply-section {
    display: block;
    margin-top: -120px;
}
.top-apply-section .clg-logoo {
  margin: 0 auto;
}
.top-apply-section .right-desc {
  padding-top: 26px;
}
}

@media (max-width: 624px) and (min-width: 500px) {

  .single-course {
    min-height: 320px;
    margin: 0 10px;
    margin-bottom: 20px;
    padding: 12px;
  }
}

@media (max-width: 880px) and (min-width: 768px) {
  .whyus-section .whyus-block {
    min-height: 160px;
  }
}

@media (max-width: 364px)  {
  .whyus-section .whyus-block {
    min-height: 136px;
  }
}

@media (max-width: 500px) {
  .collegeList-content {
    padding: 20px 14px 0 14px;
}

.courseList-box {
  padding: 15px;
  margin-bottom: 20px;
}

.review-text {
  font-size: 13px;
}

.course-links a{
  font-size: 11px;
  font-weight: 600;
  padding: 0 5px;
  margin: 0 2px;
  background: #fafafa;
}

.courseList-box a.primary-btn,
.courseList-box a.secondary-btn {
  padding: 4px 6px;
}
}

@media (max-width: 400px) {
  
  .goals-section .goalBox {
    grid-template-columns: 1fr;
  }
}