@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  display: inline-block !important;
  color: #3F4195 !important;
}

a:hover {
  text-decoration: none;
}

:root {
  --color: #3F4195;
}

.primary-color {
  color: #3F4195 !important;
}

b {
  font-size: 16px !important;
}

.common-heading {
  position: relative;
  text-align: center;
  padding-bottom: 12px;
  margin-bottom: 40px;
}

.common-heading::before,
.common-heading::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.common-heading::before {
  top: 100%;
  height: 1px;
  width: 200px;
  background-color: #000;
}

.common-heading::after {
  bottom: -10px;
  height: 22px;
  width: 38px;
  background-image: url(./images/heading-img.jpg);
  background-size: cover;
}

.common-heading h2 {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 500;
}

.primary-btn {
  background-color: var(--color);
  color: white !important;
  padding: 6px 16px;
  border-radius: 6px;
  border: 1px solid #3F4195;
}

.secondary-btn,
.secondary-btn:hover {
  background-color: #ffffff;
  color: var(--color) !important;
  padding: 6px 16px;
  border-radius: 6px;
  border: 1px solid #3F4195;
}

button {
  border: none;
}

.container-fluid {
  padding: 0 24px;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.fw-600 {
  font-weight: 600;
}

.modal.show {
  background: rgba(0, 0, 0, 0.8);
}

.modal-content {
  border: none !important;
  border-radius: 14px;
}

.close-btn {
  position: absolute;
  top: -20px;
  right: -36px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: #ffffff;
  border: none !important;
  padding: 0;
}

.close-btn:hover {
  background: #ffffff;
  box-shadow: none;
}

.close-btn svg {
  color: var(--color);
  height: 18px;
  width: auto;
  stroke-width: 1px !important;
}

.slick-dots {
  position: relative;
  bottom: 0;
}

.slick-dots li {
  height: auto;
  width: auto;
  margin: 0 5px;
}

.slick-dots li button {
  height: 10px;
  width: 10px;
  background-color: var(--color) !important;
  border-radius: 50px;
  position: relative;
  opacity: 1 !important;
  padding: 0;
}

.slick-dots li:hover button {
  background-color: var(--color) !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button {
  height: 14px;
  width: 14px;
}

.slick-dots li button::before {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff !important;
  opacity: 0;
  transition: 0.5s ease;
}

.slick-dots li button:hover::before,
.slick-dots li.slick-active button::before {
  opacity: 1;
}

.slick-slider {
  position: relative;
  margin: 0 40px;
}

.slick-arrow {
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  z-index: 300;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

.slick-next::before,
.slick-prev::before {
  color: var(--color);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 16px;
}

.slick-next::before {
  content: '\f054';
}

.slick-prev::before {
  content: '\f053';
}

.slick-next::after,
.slick-prev::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 18px;
  width: 40px;
  background: #adadad;
  opacity: 0.2;
}

.slick-arrow.slick-disabled {
  box-shadow: 0px 0px 22px -5px rgba(0, 0, 0, 0.1);
}

.slick-disabled:before {
  opacity: 0.1;
  color: #000000;
}

.slick-disabled::after {
  opacity: 0.05;
  background-color: #000000;
}

input[type='submit'] {
  background-color: var(--color);
  color: white !important;
  padding: 6px 16px;
  border-radius: 6px;
  border: none;
  margin-bottom: 0;
}

input::placeholder {
  color: #b4b4b4 !important;
  font-weight: 600;
}

.modal h4 {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 24px;
}

form input, form textarea, form select,.form-control {
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  padding: 0 0 6px 10px;
  margin-bottom: 36px;
  border-radius: 0;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus, .form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  box-shadow: none;
  border-width: 2px !important;
  border-color: var(--color) !important;
}

.form-control.is-valid, .was-validated .form-control:valid, .form-control.is-invalid, .was-validated .form-control:invalid {
  border-bottom: 1px solid #c4c4c4 !important;
}

.form--group {
  margin-bottom: 36px;
}

.form--group input,
.form--group select,
.form--group textarea{
  margin-bottom: 0;
}

form input:focus ,
form textarea:focus ,
form select:focus {
  border-width: 2px;
  border-color: var(--color);
}

form input::placeholder,
form textarea::placeholder {
  font-size: 14px;
  color: #9d9d9d;
  font-weight: 400 !important;
}

form select {
  color: #9d9d9d;
}

form select option {
  color: black;
}

input:focus,
textarea:focus ,
select:focus {
  outline: none !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.py-40 {
  padding: 40px 0 !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.font-16 {
  font-size: 16px;
}

.font-13 {
  font-size: 13px;
}

.nav-tabs {
  text-align: center;
  display: flex;
  justify-content: center;
  border-bottom: 0;
}

.nav-tabs .nav-link {
  border: none;
  margin: 0 4px;
  padding: 10px 20px;
  font-weight: 600;
  color: black;
  font-size: 16px;
  border-radius: 0 !important;
  transition: 0.5s ease;
}

.nav-tabs .nav-link:hover {
  background: var(--color);
  color: white !important;
}

.nav-tabs .nav-link.active {
  background: var(--color) !important;
  color: #ffffff !important;
}

h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 15px;
}

.light-text {
  color: #919191;
}

.loading {
  height: 100%;
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

.loading img {
  transform: rotateZ(0);
  animation: rot 2s linear infinite;
  margin: 0 auto;
}

@keyframes rot {
  100% {
    transform: rotateZ(360deg);
  }
}

/* ------------HEADER starts------------ */

header {
  padding: 10px 0 0 0;
}

header ul li a {
  color: black !important;
  font-weight: 600;
}

header ul li a .header-icons {
  color: var(--color);
  margin-right: 6px;
}

header hr {
  background-color: #919191;
  margin: 12px 0 0 0;
}

#loginModal .modal-content {
  overflow: hidden;
}

#loginModal .modal-body {
  padding: 0;
}

#loginModal .left-section {
  padding: 80px 20px 80px 40px;
  background: var(--color);
  color: white;
}

#loginModal .left-section ul {
  list-style: circle;
  padding-left: 20px;
}

#loginModal .left-section ul li {
  font-size: 14px;
  font-weight: 300 !important;
  padding: 4px 0;
  position: relative;
}

#loginModal h4 {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 20px;
}

#loginModal .form-content {
  padding: 60px 28px;
}

#loginModal .form-content p {
  color: #727272;
  text-align: center;
  margin-bottom: 28px;
}

/* Header ends */

/* -----NAVBAR starts---------- */

.main-navbar {
  position: relative;
  z-index: 999;
  padding: 10px 0;
}

.main-navbar.stick-nav {
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.1);
  transform: scaleY(0);
  transform-origin: top;
  animation: stickNav 0.4s linear forwards;
}

@keyframes stickNav {
  100% {
    transform: scaleY(1);
  }
}

.main-navbar.stick-nav .mainNav-content {
  opacity: 0;
  animation: opac 0.5s 0.3s linear forwards;
}

@keyframes opac {
  100% {
    opacity: 1;
  }
}

/* .main-navbar .logo img {
  max-height: 60px;
  width: auto;
} */

.main-navbar nav ul li {
  display: inline-block !important;
}

.main-navbar nav {
  text-align: end;
  padding-right: 80px;
}

.main-navbar nav ul li.dropdown {
  position: static;
}

.main-navbar nav ul li.dropdown a {
  position: relative;
}

.main-navbar nav ul li.dropdown .submenu a {
  font-weight: 500;
  font-size: 14px;
  padding: 4px 0;
}

.main-navbar nav ul li.dropdown a::after,
.mobile-menu ul li.dropdown a::after,
.mobile-menu .nav-item.dropdown a::after {
  content: '\f078';
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 10px;
  border: none;
  transition: .4s ease;
}

.mobile-menu ul li.dropdown a::after,
.mobile-menu .nav-item.dropdown a::after {
  top: 15px;
  transform: none;
}

.mobile-menu .nav-item.dropdown.show a::after {
  transform: rotateZ(180deg);
} 

.mobile-menu .nav-item.dropdown .dropdown-menu a::after {
  display: none;
}

.main-navbar nav ul li a {
  font-weight: 700;
  padding: 10px 20px;
  color: black;
  font-size: 16px;
  transition: 0.5s ease;
}

.main-navbar nav ul li a:hover {
  color: var(--color) !important;
}

.main-navbar nav ul li .submenu {
  position: absolute;
  top: 100%;
  right: 18px;
  background-color: white;
  text-align: left;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  width: 68vw;
  min-height: 0;
  max-height: 0;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top;
  transition: 0.5s ease;
}

.main-navbar nav ul li .submenu a::after {
  display: none !important;
}

.main-navbar nav ul li:hover .submenu {
  min-height: auto;
  max-height: 100vh;
  transform: scaleY(1);
  overflow-y: scroll;
  opacity: 1;
}

.main-navbar nav ul li:hover .submenu h4 {
  font-size: 16px;
  font-weight: 700;
  color: #8a8a8a;
}

.main-navbar nav .submenu li {
  display: block !important;
}

.main-navbar nav .submenu li a {
  display: block !important;
    width: 100%;
    font-size: 14px;
    color: #a2a2a2;
    font-weight: 500;
    padding: 2px 0 !important;
}

.main-navbar nav .submenu li a:hover {
  margin-left: 4px;
}

.main-navbar nav .submenu li a::after {
  display: none;
}

.search-box {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid black;
  color: #000 !important;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

#menuBar {
  position: absolute;
  right: 65px;
  top: 23px;
  height: 24px;
  width: 30px;
}

#menuBar > div {
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 28px;
  background-color: black;
}

#menuBar > div::before,
#menuBar > div::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 28px;
  background-color: black;
}

#menuBar > div::before {
  right: 0;
  top: 8px;
}

#menuBar > div::after {
  right: 0;
  top: 16px;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  z-index: -1;
  height: 100vh;
}

.mobile-menu.show {
  width: 100%;
  opacity: 1;
  z-index: 999;
}

.mobile-menu .wrap-container {
  width: 0;
  background-color: #ffffff;
  height: 100vh;
  overflow-y: scroll;
  transition: 0.5s ease;
}

.mobile-menu.show .wrap-container {
  width: 90%;
}

.mobile-menu .navbar-profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  background: #e3e3e3;
  padding: 10px 0 10px 16px;
  z-index: -1;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: 0.5s ease;
  transition-delay: .2s;
}

.mobile-menu.show .navbar-profile {
  opacity: 1;
}

.mobile-menu ul {
  opacity: 0;
  transition: 0.5s ease;
  transition-delay: .2s;
}

.mobile-menu.show ul {
  opacity: 1;
}

.profile-btn {
  color: #3a3a3a !important;
  font-weight: 600;
}

.profile-btn img {
  height: 50px;
  width: auto;
}

.profile-btn svg {
  height: 28px;
    width: auto;
    margin-right: 12px;
    border: 1px solid #3a3a3a;
    border-radius: 50px;
    padding: 4px;
}

.mobile-menu.show .navbar-profile {
  z-index: 1000;
}

.navbar-profile .close_mobileMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f5f5f;
  height: 50px;
  width: 24px;
  background: transparent;
  margin-right: 16px;
}

.navbar-profile .close_mobileMenu svg {
  height: 16px;
}

.mobile-menu ul:not(.submenu) {
  margin-top: 68px;
  padding: 4px 16px;
}

.mobile-menu li a,
.mobile-menu .nav-item.dropdown a:not(.dropdown-item) {
  color: #000000 !important;
  padding: 10px 0;
  font-weight: 700;
  font-size: 16px !important;
  display: block !important;
}

.mobile-menu .submenu a,.dropdown-menu a.dropdown-item {
  padding: 6px 0 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  white-space: normal;
}

.mobile-menu li:not(:last-child) {
  border-bottom: 1px solid #e3e3e3;
}

.dropdown-menu.show {
  position: relative !important;
  transform: translate3d(0,0,0);
  border: none;
}

.mobile-menu .submenu,.dropdown-menu {
  margin-left: 10px;
  transform: scaleY(0) translate3d(0,0,0) !important;
  transform-origin: top;
  min-height: 0;
  max-height: 0;
  opacity: 0;
  z-index: -1;
  transition: 0.5s ease !important;
}

.mobile-menu .submenu.show,.dropdown-menu.show {
  transform: scaleY(1) translate3d(0,0,0) !important;
  min-height: 20px;
  max-height: 1000px;
  opacity: 1;
  z-index: 999;
  padding-bottom: 16px;
  padding-top: 0;
}

.mobile-menu ul li.dropdown a::after {
  transform: none;
  top: 9px;
}

.mobile-menu .dropdown .submenu a::after {
  display: none;
}

.mobile-menu .submenu li {
  border: none !important;
}

.mobile-menu .submenu li a {
  display: block;   
  font-size: 13px;
  color: #a2a2a2;
  font-weight: 500;
  padding: 5px 0 !important;
}

.mobile-menu .submenu li a::after {
  display: none;
}

.drop-search {
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  width: 100%;
  min-height: 0;
  max-height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.5s ease;
}

.drop-search.show {
  padding: 20px 40px 30px 40px;
  min-height: 100px;
  max-height: 500px;
  transform: scaleY(1);
}

.drop-search h6 {
  margin-bottom: 14px;
  font-size: 16px;
  opacity: 0;
  line-height: 20px;
}

.drop-search .input__group {
  width: 100%;
  display: flex;
  opacity: 0;
}

.drop-search.show h6 {
  opacity: 1;
  transition: 0.5s ease;
  transition-delay: 0.2s;
}

.drop-search.show .input__group {
  opacity: 1;
  transition: 0.5s ease;
  transition-delay: 0.2s;
}

.drop-search .input__group span {
  border: 1px solid #eeeeee;
  color: #969696;
  padding: 5px 10px 0 10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.drop-search .input__group span svg {
  height: 16px;
  width: auto;
}

.drop-search .input__group input {
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  padding: 8px 20px;
}

.drop-search .input__group a {
  padding: 8px 20px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: var(--color);
  color: #ffffff !important;
}

.drop-search .input__group input::placeholder {
  font-weight: 400;
  color: #969696 !important;
}

/* Navbar ends */

/* ----------SEARCH SECTION starts---------- */

.search-section {
  height: 500px;
  background: url(./images/search-section.jpg);
  background-size: cover;
  background-position: 0 -170px;
  background-repeat: no-repeat;
  position: relative;
  color: white;
  text-align: center;
  padding: 86px 0;
  z-index: 300;
  position: relative;
}

.search-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.search-section h2 {
  position: relative;
  z-index: 100;
  font-weight: 800;
  font-size: 36px;
}

.search-section .search-block {
  width: 74%;
  margin: 0 auto;
  margin-top: 50px;
}

.search-section .search-block .input-group {
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
}

.search-section .search-block .input-group .input-group-text {
  height: inherit;
  background: white;
  color: var(--color);
}

.search-section .search-block .input-group .input-group-text svg {
  height: 20px !important;
  width: auto;
}

.search-section .search-block .input-group input {
  border: none;
  outline: none;
  height: inherit;
}

.search-section .search-block .input-group input:focus {
  box-shadow: none;
}

.search-section .search-block .input-group button,
.search-section .search-block .input-group a {
  height: inherit;
  padding-left: 30px;
  padding-right: 30px;
  /* border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important; */
  padding-top: 11px;
}

.search-section .search-block .home-search {
  background-color: #ED3237 !important;
  border: none !important;
}

/* Search Section starts */

/* ----------FEATURE SECTION starts---------- */

.feature-section {
  position: relative;
  margin-top: -80px;
  z-index: 500;
  padding-top: 150px;
  padding-bottom: 60px;
  overflow: hidden;
}

.feature-section::before,
.feature-section::after {
  content: '';
  position: absolute;
  top: -120px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-repeat: no-repeat;
}

.feature-section::before {
  background-image: url(./images/features/wave1.png);
  background-position: left;
  animation: wavvy1 20s linear alternate-reverse infinite;
}

.feature-section::after {
  background-position: right;
  background-image: url(./images/features/wave2.png);
  animation: wavvy2 20s linear alternate-reverse infinite;
}

@keyframes wavvy1 {
  100% {
    background-position: right;
  }
}

@keyframes wavvy2 {
  100% {
    background-position: left;
  }
}

.feature-section .feature-row {
  background-color: #ffffff;
  box-shadow: 0px 10px 60px -15px rgba(0, 0, 0, 0.15);
}

.feature-section .feature-box {
  padding: 20px 16px;
  border-radius: 8px;
  text-align: center;
  margin: 0 10px;
  min-height: 162px;
  position: relative;
}

.feature-section [class*="col-"]:not(:last-child) .feature-box::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 60px;
  width: 1px;
  background: #C4C4C4;
}

.feature-section .feature-box img {
  max-height: 60px !important;
  width: auto;
}

.feature-section .feature-box h5 {
  font-size: 15px;
  font-weight: 500;
  margin-top: 18px;
}

/* Feature Section ends */

/* ----------GOALS SECTION starts---------- */

.goals-section .goalsList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.goal-block {
  display: block !important;
  color: #000000 !important;
  border: 1px solid #f1f1f1;
  padding: 10px;
  height: 100%;
}

.goal-block .title--block {
  display: flex !important;
  align-items: center;
}

.goal-block .goalTitle {
  color: var(--color);
}

.goal-block .goals-courses {
  margin-top: 10px;
  border-top: 1px solid #f1f1f1;
  padding-top: 10px;
  font-size: 13px !important;
}

.goal-block p,
.goal-block span {
  color: #9d9d9d;
  margin: 0;
  font-size: 13px !important;
}

.goal-block .img-box {
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  /* box-shadow: 0px 10px 36px -10px rgba(0, 0, 0, 0.15); */
}

.goal-block img {
  height: 30px;
  width: auto;
}

.goal-block h5 {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
  text-align: left;
}

.goals--img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Goals Section Ends */

/* ----------GOALS SECTION starts---------- */

#counselling-modal .modal-content {
  text-align: center;
  background: transparent;
}

.modal-body {
  position: relative;
}

.counselling-section p {
  padding: 0 180px;
  margin-bottom: 40px;
}

.counselling-section h4 {
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 18px;
  font-size: 20px;
}

.counselling-section h4::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  height: 1px;
  width: 200px;
  background-color: #000000;
}

.counselling-section .goals-list li {
  position: relative;
  padding: 5px 0;
  padding-left: 20px;
  font-weight: 500;
}

.counselling-section .goals-list li::before,
.counselling-section .goals-list li::after {
  content: '';
  position: absolute;
}

.counselling-section .goals-list li::before {
  left: 0;
  top: 10px;
  height: 10px;
  width: 10px;
  border: 1px solid #000;
}

.counselling-section .goals-list li::after {
  left: 4px;
  top: 14px;
  height: 7px;
  width: 7px;
  background-color: #000;
}

.counselling-section .slick-slider {
  margin: 0;
}

.counselling-section .slick-slider .slick-slide {
  position: relative;
}

.counselling-section .slick-slider .slick-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.counselling-section .slick-slider .play-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 400;
  background: transparent;
  color: #ffffff;
}

.counselling-section .slick-slider .play-btn svg {
  font-size: 40px;
  color: #ffffff;
}

.counselling-section .slick-slider .slick-arrow {
  display: none !important;
}

.modal iframe {
  min-width: 780px;
  max-width: 800px !important;
  height: 450px !important;
}

/* Goals section ends */

/* ----------WHYUS SECTION starts---------- */

.whyus-section {
  background: rgba(0, 137, 232, 0.05);
  padding: 80px 0 60px 0;
}

.whyus-section .whyus-block {
  background: #ffffff;
  text-align: center;
  box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px 12px;
  margin: 0 14px;
  min-height: 132px;
  position: relative;
}

.whyus-section .whyus-block .whyus-img {
  position: relative;
  height: 92px;
  width: 92px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -64px;
  background: #3F4195;
  border: 4px solid #FFFFFF;
  border-radius: 50%;
  box-shadow: 0px 10px 40px -6px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.whyus-section .whyus-block h6 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

/* Why us section ends */

/* ----------TOP COLLEGES SECTION starts---------- */

.college-carousel .slick-slide {
  margin: 0;
  padding-bottom: 20px;
}

/* .college_block {
  margin-bottom: 20px;
} */

.college_block .img-box {
  margin: 0 18px;
  min-height: 228px;
  max-height: 228px;
  overflow: hidden;
}

.college_block .img-box img {
  /* max-width: 100%; */
  min-height: inherit;
  object-fit: cover;
}

.college-desc {
  position: relative;
  margin: 0 30px 20px 30px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.15);
  margin-top: -40px;
  display: flex;
  align-items: center;
  min-height: 78px;
  max-height: 78px;
}

.college-desc .college-logo img {
  max-width: 100px;
  max-height: 50px;
  object-fit: contain;
  margin-right: 20px;
}

.college-desc h5 {
  font-size: 15px;
  font-weight: 600;
}

.college-desc h6 {
  font-size: 13px;
  color: #9d9d9d;
  margin-bottom: 0;
}

/* Top College Section Section ends */

/* ----------STUDY ABROAD SECTION starts---------- */

.studyAbroad-section p {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 15px;
}

.studyAbroad_block {
  border: 1px solid #c4c4c4;
  padding: 18px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.studyAbroad_block img {
  height: 72px;
  width: auto;
  margin: 0 auto;
  display: block;
  margin-bottom: 8px;
}

.studyAbroad_block h5 {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 18px;
}

.studyAbroad_block .college-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.studyAbroad_block .college-info .light {
  color: #9d9d9d;
  margin-right: 10px;
}

/* Study Abroad Section ends */

/* ----------FILTER SECTION starts---------- */

.filter-buttons {
  text-align: center;
  display: flex;
  justify-content: center;
}

.filter-buttons a {
  margin: 0 20px;
  padding: 10px 28px;
  border: 1px solid #e5e5e5;
  font-weight: 600;
  color: black;
  font-size: 16px;
  transition: 0.5s ease;
}

.filter-buttons a:hover {
  background: var(--color);
  color: white !important;
  border-color: var(--color);
}

.filter-buttons a.active {
  background: var(--color);
  color: white !important;
  border-color: var(--color);
}

.filtered-courses {
  margin-top: 40px;
}

.single-course {
  min-height: 280px;
  margin: 0 16px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0px 14px 30px -10px rgb(0 0 0 / 20%);
  position: relative;
}

.single-course .single-badge {
  padding: 4px 10px !important;
  background: #f3f3f3;
  color: #979797;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
}

.single-course .desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.single-course .title {
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
}

.single-course .desc .light {
  color: #9d9d9d;
}

.single-course hr {
  background: #c4c4c4;
}

.single-course .linkOverview {
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
  width: 100%;
}

.single-course .linkOverview a {
  color: var(--color);
  text-decoration: underline;
  position: relative;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: 6px;
}

.single-course .linkOverview a::before {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 10px;
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
}

/* Filter section ends */

/* ------------NEWS SECTION starts----------- */

.news-block {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 10px;
  color: #000 !important;
}

.news-block img {
  max-width: 100%;
  height: auto;
}

.news-block .news-content {
  padding: 14px;
}

.news-block .news-content h6 {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 6px;
  display: -webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
}

.news-block .news-content span,
.news-block .news-content b {
  color: #9d9d9d;
  font-size: 13px;
}

.news-block .news-content .newsShort {
  color: #5f5f5f;
  font-size: 14px;
  font-weight: 400;
  margin: 12px 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* News Section ends */

/* ---------------TESTIMONIALS SECTION starts--------------- */

.testimonial-slider .slick-prev {
  left: 0px;
}

.testimonial-slider .slick-next {
  right: 0;
}

.testimonial-block {
  border: 1px solid #c4c4c4;
  padding: 30px;
  position: relative;
  margin: 80px 100px 2px 100px;
}

.testimonial-block::before,
.testimonial-block::after {
  content: '';
  position: absolute;
  height: 50px;
  width: 50px;
  background-image: url(./images/testimonials/left-quotes.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.testimonial-block::before {
  left: 16px;
  top: 10px;
}

.testimonial-block::after {
  right: 16px;
  bottom: 10px;
  transform: rotateZ(180deg);
}

.testimonial-block .img-sec {
  height: 140px;
  width: 140px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: -100px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.testimonial-block img {
  max-width: 100%;
  height: auto;
  display: block;
}

.testimonial-block p {
  text-align: center;
  color: #727272;
  font-weight: 400;
  margin: 40px 0;
  line-height: 26px;
  margin: 0 100px;
  margin-top: 30px;
}

.testimonial-block h5 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}

/* Testimonial Section ends */

/* --------------FOOTER SECTION starts------------- */

footer {
  padding: 60px 0 30px 0;
  background: rgb(10, 10, 10);
  color: #ffffff;
}

.footer-logo img {
  max-height: 60px;
}

footer .contact {
  margin-top: 40px;
}

footer .contact a {
  display: block !important;
  color: #ffffff !important;
  font-weight: 400;
  margin-bottom: 10px;
}

footer .footer-list h5 {
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

footer .footer-list a {
  padding: 4px 0;
  font-weight: 400;
  color: #e5e5e5 !important;
  position: relative;
  transition: 0.5s ease;
}

footer .footer-list a:hover {
  color: var(--color) !important;
  padding-left: 14px;
}

footer .footer-list a::before {
  content: '\f054';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 10px;
  color: var(--color) !important;
  opacity: 0;
  transition: 0.5s ease;
}

footer .footer-list a:hover::before {
  opacity: 1;
}

.social-links {
  text-align: center;
  margin: 30px 0;
}

.social-links li {
  display: inline-block;
}

.social-links li a {
  height: 34px;
  width: 34px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff !important;
  margin: 0 8px;
  padding-top: 4px;
  transition: 0.5s ease;
}

.social-links li a:hover {
  background-color: #ffffff;
}

.social-links li a svg {
  width: 20px;
  transition: 0.5s ease;
}

.social-links li a:hover svg {
  color: var(--color);
}

footer hr {
  margin: 0 0 30px 0;
}

.footer-bottom {
  font-weight: 300;
}

.footer-bottom a {
  font-weight: 500;
  color: #ffffff !important;
}

.sticky-social {
  position: fixed;
  bottom: 20px;
  left: 0;
  z-index: 990;
}

.sticky-social a i {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  background-color: var(--color);
  color: #fff !important;
  margin-bottom: 10px;
}

.sticky-social a i.fa-whatsapp {
  font-size: 16px;
}

/* Footer Section ends */

/* -----------PAGE COMMON STYLINGS---------- */

.breadcrumb-section {
  position: relative;
  height: 200px;
  margin-bottom: 40px;
  /* background-image: url(./images/breadcrumb/contact.jpg); */
  background-position: center 42px;
  background-size: cover;
  background-repeat: no-repeat;
}

.breadcrumb-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.breadcrumb-content {
  /* border-top: 1px solid #e6e6e6; */
  /* height: 100px; */
}

.breadcrumb-content nav {
  
  position: absolute;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center  !important;
  justify-content: start;
  width: 100%;
  background: #f4fbff;
  z-index: 100;
  padding: 8px 0 ;
  padding-left: 14px;
}

.breadcrumb-content ol {
  margin-bottom: 0;
  justify-content: start;
}

.breadcrumb-content h3 {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  color: #fff;
  z-index: 10;
  margin-bottom: 0;
}

.breadcrumb {
  justify-content: center;
}

.breadcrumb .breadcrumb-item {
  position: relative;
  padding: 0 18px;
  color: var(--color);
  font-weight: 600;
}

.breadcrumb .breadcrumb-item.active {
  color: #5f5f5f;
}

.breadcrumb li {
  position: relative;
  padding: 6px 16px 6px 12px;
  line-height: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb li svg {
  font-size: 16px;
  margin-top: -4px;
}

.breadcrumb li:not(:last-child):before {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 10px;
  position: absolute;
  right: 0;
  top: 6px;
  color: #5f5f5f;
}

.breadcrumb .breadcrumb-item.active::before {
  display: none;
}

/* Page Common Styling Pages */

/* --------------------CONTACT PAGE Starts------------------ */

.contact-section {
  background: rgba(0, 137, 232, 0.1);
  padding-top: 60px;
}

.contact-left {
  padding: 0 100px;
  margin-bottom: 60px;
}

.contact-block {
  display: block !important;
  margin-bottom: 20px;
  text-align: center;
}

.contact-block hr {
  width: 50%;
  margin: 10px auto;
  background: #727272;
}

.contact-block .img_sec {
  margin: 0 auto;
  background: var(--color);
  border: 4px solid white;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  box-shadow: 0 20px 30px -4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.contact-block .img_sec::before,
.contact-block .img_sec::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
  height: 100%;
  width: 100%;
  opacity: 1;
  animation: anim1 5s linear infinite;
  border-radius: 50%;
  background: rgba(0, 137, 232, 0.4);
  z-index: -1;
}

.contact-block .img_sec::after {
  animation-delay: 2s;
}

@keyframes anim1 {
  100%{
    opacity: 0;
    transform: scale(1.5);
  } 
}

.contact-block .img_sec img {
  height: 50px;
  width: auto;
}

.contact-block span {
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.contact-form {
  margin: 0 auto;
  max-width: 700px;
  background: #ffffff;
  box-shadow: 0px 0px 50px -10px rgba(0, 137, 232, 0.24);
  border-radius: 8px;
  padding: 40px;
}

.contact-form h4,
.carrer_form h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.contact-section iframe {
  margin-top: 60px;
}

.contact-form input[type="submit"] {
  margin: 0;
}

/* Contact Page Ends */

/* ---------------ABOUT PAGE Starts--------------- */


.about-section div {
  line-height: 24px;
}

.achievement-section {
  margin-top: 40px;
  padding: 40px 0;
  background: rgba(0, 137, 232, 0.2);
}

.achievement-section .common-heading::after {
  background-image: url(./images/heading-img-blue.jpg);
}

.achievement {
  box-shadow: 0px 12px 62px -6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 0 50px;
  text-align: center;
}

.achievement h3 {
  padding: 20px 0 4px 0;
}

.achievement h3 span{
  font-size: 18px;
  font-weight: 700;
}

.achievement h6 {
  text-transform: uppercase;
  font-weight: 600;
}

/* About Page ends */

/* ---------------CAREER PAGE Starts--------------- */

.career-section div {
  line-height: 24px;
}

.accordion-item {
  border: 1px solid #E5E5E5 !important;
  padding: 10px;
  margin-bottom: 20px;
}

.accordion-header button {
  display: block;
  width: 100%;
}

.accordion-header button span {
  display: block;
  color: #5f5f5f;
}

.accordion-header button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background: #ffffff !important;
}

.jobTitle {
  font-weight: 600;
font-size: 18px;
color: #3F4195;
margin-bottom: 0;
}

.jobExperience {
  color: #9D9D9D;
  position: relative;
}

.jobExperience::before {
  content: '';
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 12px;
  left: 0;
  top: 2px;
  color: #9D9D9D;
}

.description {
  list-style: circle;
}

.accordion-body {
  border-top: 1px solid #E5E5E5 !important;
  padding-top: 20px;
}

.accordion-body p {
  margin: 0;
}

.accordion-body hr {
  background: #E5E5E5;
  opacity: 1;
  margin: 26px 0;
}

.accordion-body h5 {
  font-size: 16px;
  font-weight: 700;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.carrer_form {
  padding: 50px 0;
  background: rgba(0, 137, 232, 0.2);
}

.carrer_form .career_form_content {
  max-width: 720px;
  box-shadow: 0px 11px 52px -10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 0 auto;
  padding: 40px;
  padding-bottom: 20px;
  background: #ffffff;
}
/* Career Page Ends */

/* ---------------CAREER PAGE Starts--------------- */

.top-apply-section {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  margin-top: -100px;
  position: relative;
}

.left-college-detail, .left-news-detail {
  padding: 0 40px;
}

.top-apply-section .college-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--color);
  margin-bottom: 0;
}

.top-apply-section .clg-logoo {
  height: 160px;
  width: 160px;
  background-color: #fff;
  padding: 10px;
    box-shadow: 0 10px 20px -4px rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;
  margin-right: 40px;
}

.top-apply-section img {
  height: 140px;
  width: 140px;
  object-fit: contain;
}

.top-apply-section .right-desc {
  padding-top: 98px;
  width: 100%;
}

.location-details {
  color: #919191;
}

.top-apply-section .secondary-btn {
  margin-right: 20px;
}

.star-container svg {
  height: 20px !important;
  width: 20px !important;
}

.college-details-tab {
  line-height: 24px;
}

.college-details-tab .nav-tabs {
  background: #f7f7f7;
  border-top: 1px solid #eeeeee; 
  border-bottom: 1px solid #eeeeee; 
}

.college-details-tab .tab-content {
  padding: 40px 0;
}

.college-details-tab .nav-tabs::-webkit-scrollbar {
  height: 3px;
}
.college-details-tab .nav-tabs::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}
.college-details-tab .nav-tabs::-webkit-scrollbar-thumb {
  background: var(--color);
}

.common-right-advertisement {
  position: sticky;
  top: 92px;
  height: 100%;
}

.common-right-section .box1,
.common-right-section .box2 {
  border: 1px solid #e3e3e3;
  padding: 18px 22px;
}

.common-right-section .box1 h5,
.common-right-section .box2 h5 {
  font-size: 16px;
  margin-bottom: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid #3F4195;
}

.common-right-section .box1 svg,
.common-right-section .box2 svg {
  height: 20px;
  width: auto;
  margin-right: 6px;
}

.common-right-section .box1 ul,
.common-right-section .box2 ul {
  margin-bottom: 10px;
}

.common-right-section .box1 ul li,
.college-details-tab ul li {
  position: relative;
  padding: 6px 0;
  padding-left: 12px;
}

.college-details-tab ul li {
  padding-left: 20px;
}

.common-right-section .box1 ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 15px;
  height: 6px;
  width: 6px;
  transform: rotateZ(45deg);
  background: #3a3a3a;
}

.common-right-section .box1 ul li button {
  color: #000;
  border: none;
  padding: 0;
  background-color: transparent;
}

.common-right-section .box1 ul li button:focus {
  box-shadow: none;
}

.common-right-section .box1 ul li a {
  color: black;
  font-weight: 500;
  transition: .5s ease;
}

.common-right-section .box1 ul li a:hover {
  color: var(--color) !important;
}

.common-right-section .box2 ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  font-size: 13px !important;
}

.common-right-section .box2 ul li:not(:last-child) {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 10px;
}

.common-right-section .box2 ul li a {
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 13px !important;
  min-width: 90px;
}

.common-right-section .box2 ul li h6 {
  color: #4e4e4e;
  margin-bottom: 2px;
  font-size: 14px !important;
  font-weight: 700;
}

.common-right-section .box2 ul li .location-clg {
  color: #727272;
  font-size: 13px !important;
}

.common-right-section .box2 ul li svg {
  height: 13px;
  color: #acacac;
}

.college-feature-box {
  min-height: 70px;
}

.description_college ul {
  padding-left: 0 !important;
}

.description_college ul li {
  position: relative;
  padding-left: 14px;
}

.description_college ul li::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  height: 7px;
  width: 7px;
  border-radius: 50px;
  background-color: #9a9a9a;
}

.course__box .primary-btn {
  font-size: 13px;
  padding: 2px 12px;
  min-width: 92px;
}

/* .course__box .light-text {
  font-size: 12px;
}

.course__box span {
  font-size: 13px;
  display: inline-block;
} */

.detailsName_fees {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  display: block;
  margin-bottom: 6px !important;
}

.college-features {
  margin-bottom: 40px;
}

.college-feature-box {
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  border-radius: 6px;
  margin:  0 6px 20px 6px;
}

.college-features svg {
  height: 40px;
  width: auto;
  color: #3F4195;
}

.college-feature-box span {
  display: block;
  font-weight: 500;
}

.college-feature-box span:not(.light-text) {
  font-size: 13px;
}

.college-details-section table {
  margin: 0 auto;
  margin-top: 30px;
}

.college-details-section table thead {
  background: #eeeeee;
}

.college-details-section table tbody {
  border-top: 1px solid #e3e3e3 !important;
}

.college-details-section table tbody p {
  margin: 0;
}

.course__box {
  border-bottom: 2px solid #f7f7f7;
  margin-bottom: 30px;
  display: block !important;
  padding-bottom: 15px;
  margin-bottom: 15px;
  /* background-color: #f7f7f7;
  box-shadow: 0 10px 10px  rgba(0, 0, 0, 0.04); */
}

/* .course__box .heading-block {
  padding: 10px;
} */

.course__box .desc {
  /* border-top: 1px solid #e3e3e3; */
  color: #727272;
  font-weight: 500;
  /* margin-top: 10px; */
  /* padding-top: 10px; */
  /* padding: 10px; */
  background-color: #ffffff;
}

.course__box  .light-text {
  font-size: 13px;
  font-weight: 600;
}

.course__box .desc p {
  margin: 0;
}

#applyNow-modal .modal-body {
  padding: 28px !important;
}

#applyNow-modal .college-heading-block {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#applyNow-modal .college-heading-block img {
  max-height: 60px;
  width: auto;
}

#applyNow-modal .college-heading-block h4 {
  text-align: left !important;
  padding: 0;
  color: var(--color);
}

#applyNow-modal .college-heading-block h6 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.admission_form:not(#applyNow-modal .admission_form) {
  margin: 40px;
  padding: 24px;
  box-shadow: 0 4px 40px -10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.placementRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 14px;
  column-gap: 14px;
}

.placement_box {
  border: 1px solid #e3e3e3;
  padding: 10px;
  text-align: center;
}

.placement_box img {
max-height: 40px;
min-height: 40px;
max-width: 100px;
object-fit: contain;
margin: 0 auto;
padding-bottom: 10px;
margin-bottom: 10px;
border-bottom: 1px solid #e3e3e3;
}

.placement_box h4 {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize !important;
  margin-bottom: 0;
}

.top-apply-btn {
  padding: 0;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.top-apply-btn span {
  display: inline-block !important;
  padding: 10px 38px;
  text-transform: uppercase;
}

.top-apply-btn .apply-imgg {
  padding: 10px 12px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.25);
}

/* College Detail Page ends */

/* --------------COLLEGE PAGE Starts---------------- */

.collegeList-section {
  background: #F3F3F3;
  padding: 40px 0;
}

.filter-downdown {
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.filter-downdown h5 {
  font-size: 16px;
  color: var(--color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-downdown h5 span {
  font-size: inherit;
}

.filter-downdown h5 svg {
height: 12px;
width: auto;
}

.filter-downdown hr {
  background: #F3F3F3;
  opacity: 1;
}

.filter-downdown input[type="search"] {
  background: #f3f3f3;
  width: 100%;
  border: none;
  padding: 8px 0 8px 12px;
  margin-bottom: 10px;
}

.filter-downdown input[type="search"]::placeholder {
  color: #C4C4C4;
}

.filter-dropdown-content ul {
  max-height: 200px;
  overflow-y: auto;
}

.filter-dropdown-content ul li {
  position: relative;
  padding: 4px 0;
}

.filter-dropdown-content ul li label {
  display: block;
  width: 100%;
}

.form_check {
  position: relative;
}

.form_check span:not(.checkmark) {
  padding-left: 10px;
}

.form_check input {
  opacity: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  top: 3px;
  height: 14px;
  width: 14px;
  border: 1px solid #3F4195;
  border-radius: 3px;
}

.form_check input:checked ~ .checkmark {
  background: var(--color);
}

.checkmark::before {
  content: '\f00c';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 10px;
  color: white;
  display: none;
}

.form_check input:checked ~ .checkmark::before {
  display: block;
}

.collegeList-content {
  background: #ffffff;
  padding: 30px 30px 0 30px;
}

.courseList-box {
  box-shadow: 0px 14px 50px -12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 30px;
}

.courseList-box h6 {
  line-height: 24px;
}

.courseList-box .location-details span {
  font-size: 13px;
}

.courseList-box img {
  max-width: 86px;
  height: auto;
}

.course-links a {
  color: #9D9D9D !important;
  padding: 0 10px;
  font-size: 13px;
}

.filter__column {
  padding: 10px 0 0 10px;
}

.filter__column .nav-item {
width: 100%;
}

.filter__column .nav-link {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  padding-left: 8px;
}

.filter__column .nav-link svg {
  height: 10px;
  width: auto;
}

.filter__column .nav-link.active {
  background: rgba(0, 137, 232, 0.1);
  color: var(--color);
}

#filter_btn {
  background-color: #3F4195;
  padding: 6px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
  color: #fff !important;
  text-transform: uppercase;
  box-shadow: 0 10px 15px -4px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
}

#filter_btn svg {
  height: 18px;
  width: auto;
  margin-right: 10px;
}

.apply_btns {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.apply_btns a {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  display: block !important;
  border-radius: 0;
}
.apply_btns a:first-child {
  border-right: none;
}

/* College Page Ends */

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4; 
  border-radius: 10px;
  transition: .5s ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #727272; 
}

/* ---------GALLERY PAGE Starts----------- */

.gallery-images button {
  background: transparent;
  border-radius: 0;
  border: none;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
}

.gallery-images button:hover {
  background: transparent !important;
}

.gallery-images button:focus {
  box-shadow: none !important;
  background: transparent;
}

.gallery-images button img {
  max-height: 180px;
  min-height: 180px;
  width: 100%;
  object-fit: cover;
}

.gallery-modal .modal-dialog {
  max-width: 100%;
}

/* .gallery-modal button { 
  display: block;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.gallery-modal button:hover { 
  background-color: transparent !important;
} */

.gallery-modal img{
  margin: 0 auto;
  max-height: 82vh;
}

.gallery-modal .modal-dialog .modal-content {
  background: rgba(0, 0, 0, 0.1) !important;
}

.gallery-modal  .close-btn {
  top: -20px;
  right: 10px;
  background: transparent;
}

.gallery-modal .close-btn svg {
  color: white;
  height: 30px;
}

.gallery-modal  .image-gallery-image {
  max-height: 72vh !important;
}


/* Gallery Page Ends */

/* -------PROFILE PAGE Starts----------- */

.profile-section {
  padding: 60px 0;
  background: #F3F3F3;
}

.profile-left-section {
  background: white;
  padding: 14px;
  border-radius: 12px;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.1);
}

.left_profile_set {
  max-width: 100px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
}

.profile-image {
  margin: 0 auto;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 20px;
}

.profile-image img {
  max-height: 70px;
}

.edit-profile {
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  right: 10px;
  height: 26px;
  width: 26px;
  border: 1px solid #acacac;
  color: #acacac;
  background: white;
  border-radius: 50%;
  transition: .5s ease;
  cursor: pointer !important;
}

.edit-profile input,.edit-profile svg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer !important;
}


.edit-profile input {
  opacity: 0;
  height: inherit;
  width: inherit;
  cursor: pointer !important;
}

.edit-profile:hover {
  color: var(--color) !important;
}

.edit-profile svg {
  height: 13px;
  position: relative;
}

.profile-left-section .profile-details {
  padding: 0 14px;
  padding-bottom: 18px;
}

.profile-left-section .nav-pills {
  border-top: 1px solid #dfdfdf;
  padding-top: 10px;
}

.profile-left-section .nav-pills .nav-item a {
  background: transparent !important;
  color: #5f5f5f !important;
  font-weight: 600;
  cursor: pointer;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.profile-left-section .nav-pills .nav-item a svg {
  margin-right: 12px;
}

.profile-left-section .nav-pills .nav-item a.active {
  color: var(--color) !important;
}

.profile-right-section {
  background: white;
  padding: 20px;
  border-radius: 12px;
}

.right-tab-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}

.right-tab-heading h4 {
  font-weight: 700;
  font-size: 16px;
}

.right-tab-heading svg {
  color: var(--color);
}

.detail-block {
  padding: 14px;
  border: 1px solid #dfdfdf;
}

.detail-block .single-detail {
  max-width: 340px;
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.detail-block span {
  color: #acacac;
}

.common-profile-modal .modal-content,
.common-profile-modal .modal-body {
  padding: 0;
}

.common-profile-modal .modal-body {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border-bottom: 2px solid #3F4195;
}

.common-profile-modal form {
  padding: 0 20px;
}

.common-profile-modal .close-btn {
  background: var(--color);
}

.common-profile-modal .close-btn svg {
  color: #ffffff;
}

.common-profile-modal h4 {
  padding: 12px 20px;
  text-align: left;
  color: #ffffff;
  padding-bottom: 12px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  word-spacing: 10px;
  background: var(--color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.common-profile-modal input[type="submit"] {
  width: auto;
}

.common-profile-modal form .form-label {
  margin-bottom: 0;
}

.common-profile-modal form input[type="submit"],
.common-profile-modal form a.primary-btn,
.common-profile-modal form a.secondary-btn {
  margin-bottom: 20px;
  font-size: 15px;
}

/* Profile Page Ends */

/* --------------NEWS SECTION Starts--------------- */

.news-page-section .news-block {
  margin: 0;
  margin-bottom: 20px;
}

.singleNews-section h5 {
  color: var(--color) ;
  font-size: 20px;
}

.singleNews-section .name {
  font-weight: 600;
  color: #4e4e4e;
}

.singleNews-section .headline {
  font-weight: 700;
  font-style: italic;
  color: #4e4e4e;
  margin: 15px 0;
  position: relative;
  padding-left: 18px;
}

.singleNews-section .headline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: var(--color);
}

.singleNews-section img {
  margin-bottom: 16px;
}

/* News Section Ends */

/* -----------BLOG PAGE Starts------------ */


.blog-section {
  padding: 0 50px;
}

.blog-box img {
  max-height: 250px;
  min-height: 250px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.blog-box {
  color: #000000 !important;
  margin: 10px;
  margin-bottom: 24px !important;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.2);
}

.blog-content {
  padding: 16px;
  background: rgba(0, 137, 232, 0.05);
}

.blog-content h5{
  font-weight: 700;
  font-size: 18px;
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-content .date,
.blog-content .author{
  font-weight: 500;
  color: #5f5f5f;
}

.blog-content .date {
  margin-left: 20px;
}

.blog-content .blog-description {
  display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-content p {
  margin-top: 14px;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 24px;
}

.left-news-detail .blog-content p {
  display: block;
}

.left-news-detail .blog-content h5 {
  font-size: 24px;
  font-weight: 600;
}

/* Blog Page Ends */

/* ----------COURSE PAGE Strats----------- */

.about-course {
  border: 1px solid #e5e5e5;
  padding: 20px;
}

.about-course h4 {
  color: var(--color);
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.about-course h4::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  height: 3px;
  width: 60px;
  background: var(--color);
  border-radius: 50px;
}

.course-page-section a:not(.primary-btn) {
  color: var(--color) !important;
}

/* Course Page Ends */

.no-data-block {
  font-size: 16px;
  font-weight: 600;
  color: red;
  text-align: center;
  padding-bottom: 40px;
}

/* --------------TERMS PAGE Starts--------------- */

.terms-section h4 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 22px;
  color: #5f5f5f;
}

.terms-section ul {
  padding-left: 28px !important;
  list-style-type: disc;
  margin-bottom: 10px;
}

/* Terms Page Ends */

/* --------------NOT FOUND PAGE Starts--------------- */

.notFound-section h2 {
  font-size: 32px;
}

.notFound-section p {
  margin: 30px 0 20px 0;
  font-size: 16px;
}

/* Not Found Page Ends */